<template>
  <BaseWidget>
    <template #title>
      <span>תובנות</span>
    </template>
    <template #default>
      <BarChart :data="chartData" title="הצלחות מול אי הצלחות" />
    </template>
  </BaseWidget>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const students = [
  { name: 'Alice', grade: 23 },
  { name: 'Bob', grade: 47 },
  { name: 'Charlie', grade: 68 },
  { name: 'David', grade: 89 },
  { name: 'Eva', grade: 35 },
  { name: 'Frank', grade: 52 },
  { name: 'Grace', grade: 74 },
  { name: 'Hannah', grade: 95 },
  { name: 'Ivy', grade: 20 },
  { name: 'Jack', grade: 80 },
];

// Initialize counts for each category
const categories = {
  '0-25': 0,
  '25-50': 0,
  '50-75': 0,
  '75-100': 0,
};

// Categorize each student's grade
students.forEach(student => {
  const { grade } = student;
  if (grade >= 0 && grade < 25) {
    categories['0-25'] += 1;
  } else if (grade >= 25 && grade < 50) {
    categories['25-50'] += 1;
  } else if (grade >= 50 && grade < 75) {
    categories['50-75'] += 1;
  } else if (grade >= 75 && grade <= 100) {
    categories['75-100'] += 1;
  }
});

const chartData = ref({
  labels: Object.keys(categories),
  datasets: [
    {
      label: 'הצלחות',
      data: Object.values(categories),
      backgroundColor: '#36A2EB',
      hoverBackgroundColor: '#36A2EBCC',
      barPercentage: 0.76,
      categoryPercentage: 0.86,
    },
  ],
});
import { BaseWidget, BarChart } from '@nwire/amit-design-system';
</script>
