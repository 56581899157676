<template>
  <BaseTable
    v-if="mappedStudents.length"
    :headers="tableHeaders"
    :dence="true"
    :items="mappedStudents"
    include-search
    label="תלמידים">
    <template #columnheader="{ header }">
      <div
        v-if="header.key === 'studentName'"
        class="flex justify-start p-4 flex-1">
        <span>{{ header.title }}</span>
      </div>
      <div v-else class="flex justify-center p-4 flex-1 whitespace-nowrap">
        <span>{{ header.title }}</span>
      </div>
    </template>
    <template #columnbody="{ header, item }">
      <div
        v-if="header.key === 'studentName'"
        class="flex flex-col items-start justify-center p-4">
        <span
          class="truncate font-simplerBold cursor-pointer text-secondary-900 text-sm">
          {{ item.studentName }}
        </span>
      </div>
      <div
        v-else
        class="flex items-center justify-center py-2 whitespace-nowrap">
        <span
          class="rounded-full flex items-center justify-center font-simplerBold h-9 w-9 text-xs"
          :class="getSubmission(item.id, header.exercise?.id).color"
          @click="
            !getSubmission(item.id, header.exercise?.id).readonly &&
              $router.push({
                name: 'review-submissions',
                params: { group, course, lesson },
                query: {
                  student: item.id,
                  exercise: header.exercise?.id,
                  review_per: 'exercise',
                },
              })
          ">
          {{ getSubmission(item.id, header.exercise?.id).text }}
        </span>
      </div>
    </template>
    <template #actions></template>
  </BaseTable>
</template>

<script setup lang="ts">
import { computed, onMounted, PropType } from 'vue';
import BaseTable from '/@/views/playground/partials/BaseTable.vue';
import type { IStudent } from '/@/app/models/interfaces';
import { RouteParams, useRoute } from 'vue-router';
// import { FileText } from 'lucide-vue-next';
import { useCourseStore } from '/@/app/store/course';
import { sortStudents } from '/@/utils';

// Define props for students and submissions
const props = defineProps({
  students: {
    type: Array as PropType<IStudent[]>,
    default: () => [],
  },
  submissions: {
    type: Array as any,
    default: () => [],
  },
});

const route = useRoute();
const {
  group,
  course,
  lesson,
}: RouteParams | { group: any; course: any; lesson: any } = route.params;
const courseStore = useCourseStore();

onMounted(() => {
  courseStore.fetchExercises(route.params.course, route.params.lesson);
});

// Function to get submission details
const getSubmission = (student, exercise) => {
  const submission = props.submissions.find(
    submission =>
      submission.participants.includes(student) &&
      submission.exercise === exercise,
  );

  const defaultResult = {
    text: '--',
    color: 'bg-secondary-200 text-secondary-700',
    readonly: true,
  };

  if (!submission) {
    return defaultResult;
  }

  const reviewed = submission.reviewed_by;

  const statusMappings = {
    pending: {
      text: '--',
      color: 'bg-primary-200 text-primary-700',
      readonly: true,
    },
    finished: {
      text: reviewed ? 'נבדק' : Math.round(submission?.score),
      color: reviewed
        ? 'text-primary-700 cursor-pointer'
        : getColorByScore(submission?.score),
    },
    changes_requested: {
      text: 'ממתין לשינוי',
      color: 'text-primary-700 cursor-pointer',
    },
    awaiting_review: {
      text: 'לבדיקה >',
      color: 'text-primary-700 cursor-pointer',
    },
  };

  return statusMappings[submission?.status] || defaultResult;
};

// Function to determine the color based on score
const getColorByScore = score => {
  if (score < 50) {
    return 'bg-red-100 text-red-700 cursor-pointer';
  }
  if (score < 75) {
    return 'bg-orange-mango-100 text-orange-mango-500 cursor-pointer';
  }
  return 'bg-green-spring-100 text-green-spring-700 cursor-pointer';
};

// Sort students
const sortedStudents = computed(() => sortStudents(props.students));

// Define table headers for BaseTable
const tableHeaders = computed(() => [
  {
    title: 'תלמידים',
    align: 'start',
    key: 'studentName',
    sortable: true,
    style: 'width: 6rem',
    frozen: true,
  },
  ...courseStore.exercises.map((exercise, index) => ({
    title: exercise.title,
    align: 'center',
    key: `exercise-${index}`,
    style: 'width: 10rem',
    exerciseId: exercise.id,
    exercise,
  })),
]);

// Map students to include necessary keys
const mappedStudents = computed(() => {
  return sortedStudents.value.map(student => ({
    id: student.id,
    studentName: student.fullName,
    ...courseStore.exercises.reduce((acc, exercise, index) => {
      acc[`exercise-${index}`] = exercise.id; // Add dynamic exercise keys
      return acc;
    }, {}),
  }));
});
</script>
