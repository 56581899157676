<template>
  <BaseWidget>
    <template #title>
      <span>סטטוס שיעור</span>
    </template>
    <template #default>
      <div class="py-1 flex gap-3 items-center mb-2 mt-2">
        <div class="font-simplerBold w-[100%]">
          <BaseAlert
            type="success"
            :icon="false"
            class="mb-4 flex items-center rounded-none">
            <span class="text-green-500">
              {{ enrollmentData.totalStudents }} /
              {{ enrollmentData.finished }} השלימו את השיעור
            </span>
          </BaseAlert>
          <BaseAlert
            type="info"
            :icon="false"
            class="mb-4 flex items-center rounded-none">
            <span class="text-blue-sea-950">
              {{ enrollmentData.totalStudents }} /
              {{ enrollmentData.inProgress }} נמצאים כרגע בשיעור
            </span>
          </BaseAlert>
          <BaseAlert
            type="danger"
            :icon="false"
            class="mb-4 flex items-center rounded-none">
            <span class="text-red-800">
              {{ enrollmentData.totalStudents }} /
              {{ enrollmentData.failed }} נכשלו בשיעור
            </span>
          </BaseAlert>
          <BaseAlert
            type="dark"
            :icon="false"
            class="flex items-center rounded-none">
            <span class="text-secondary-900">
              {{ enrollmentData.totalStudents }} /
              {{ enrollmentData.notStarted }} לא התחילו את השיעור
            </span>
          </BaseAlert>
        </div>
      </div>
    </template>
  </BaseWidget>
</template>

<script setup lang="ts">
import { BaseWidget, BaseAlert } from '@nwire/amit-design-system';
import { watch, computed } from 'vue';
import { useStudentsStore } from '/@/app/store/students';
const studentsStore = useStudentsStore();

const props = defineProps({
  courseId: {
    type: String,
    default: '',
  },
  lessonId: {
    type: String,
    default: '',
  },
  groupId: {
    type: String,
    default: '',
  },
});
watch(
  () => props.courseId,
  () => {
    studentsStore.fetchEnrollments({
      course: props.courseId,
      group: props.groupId,
    });
  },
);
const enrollmentData = computed(() => {
  const data = {
    totalStudents: studentsStore.enrollments.length,
    finished: 0,
    inProgress: 0,
    failed: 0,
    notStarted: 0,
  };
  studentsStore.enrollments.forEach(enrollment => {
    const lesson = enrollment.summary.find(
      summaryLesson => summaryLesson.lesson.id === props.lessonId,
    );
    if (lesson) {
      if (lesson.progress === 100) {
        data.finished++;
      } else if (lesson.progress > 0 && lesson.progress < 100) {
        data.inProgress++;
      } else if (lesson.progress === 0) {
        data.notStarted++;
      }
    }
    data.failed =
      data.totalStudents - data.finished - data.inProgress - data.notStarted;
  });
  return data;
});
</script>
