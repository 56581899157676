<template>
  <Transition name="fade">
    <div
      v-if="accountStore.selectedCourse"
      class="bg-[#EDF4FF] rounded-md relative px-[24px] py-[28px] flex justify-between mb-5">
      <div class="flex justify-between w-full items-start">
        <div class="flex flex-col">
          <div class="flex items-center gap-1">
            <span class="font-simplerRegular text-sm text-secondary-800">
              {{ accountStore.selectedCourse?.disciplines?.[0] }}
            </span>
            <span class="font-simplerRegular text-base text-secondary-800">
              /
            </span>
            <RouterLink
              :to="{
                name: 'course-progress',
                params: {
                  group: route.params.group,
                  course: route.params.course,
                },
              }">
              <span
                class="font-simplerBold text-[22px] text-secondary-900 underline">
                {{ accountStore.selectedCourse?.caption }}
              </span>
            </RouterLink>
            <span class="font-simplerRegular text-base text-secondary-800">
              /
            </span>
            <span class="font-simplerBold text-[24px] text-secondary-900">
              {{ currentLesson?.title }}
            </span>
          </div>
          <div class="flex items-center gap-1 text-md -mt-1">
            <span class="font-simplerBold text-secondary-800">
              {{ currentGroup?.name }}
            </span>
          </div>
        </div>
        <div class="flex items-center gap-2 mt-2">
          <RouterLink
            class="flex items-center text-primary font-simplerBold text-sm"
            target="_blank"
            :to="{
              name: 'preview',
              params: {
                course: route.params.course,
                lesson: route.params.lesson,
              },
            }">
            <PVButton
              class="flex-row-reverse h-[33px]"
              label="צפייה בתוכן השיעור >"/>
          </RouterLink>
        </div>
      </div>
    </div>
  </Transition>

  <LessonTable
    v-if="currentGroup?.students?.length"
    :students="currentGroup.students"
    :submissions="studentsStore.submissions"/>

  <SideWidget base>
    <StatusLessonWidget
      :lesson-id="lessonId"
      :course-id="accountStore.selectedCourse.id"
      :group-id="accountStore.selectedGroup.id"/>
  </SideWidget>
  <SideWidget base>
    <InsightsWidget />
  </SideWidget>
</template>

<script setup lang="ts">
import { useStudentsStore } from '/@/app/store/students';
import InsightsWidget from '/@/views/partials/InsightsWidget.vue';
import PVButton from 'primevue/button';
import StatusLessonWidget from '/@/views/partials/StatusLessonWidget.vue';
import { onMounted, ref, watchEffect } from 'vue';
import { useAccountStore } from '/@/app/store/account';
import { RouteParams, useRoute } from 'vue-router';
import { isEmpty } from 'lodash';
import LessonTable from '/@/views/components/LessonTable.vue';
import { SideWidget } from '@amit/layout';
import { fetchLesson } from '/@/app/services/courses';

const accountStore = useAccountStore();
const studentsStore = useStudentsStore();
const route = useRoute();
let currentGroup = ref<any>(null);
let currentLesson = ref<any>(null);
const lessonId = ref<any>(route.params.lesson);

const load = () => {
  const {
    group,
    course,
    lesson,
  }: RouteParams | { group: string; course: string; lesson: string } =
    route.params;

  currentGroup.value = accountStore.groups.find(
    acGroup => acGroup.id === group,
  );
  if (!accountStore.selectedGroup) {
    accountStore.selectedGroup = currentGroup;
  }
  accountStore.selectedCourse = currentGroup.value?.courses.find(
    acCourse => acCourse.id === course,
  );
  studentsStore.fetchSubmissions(route.params.group, route.params.course);

  const { onSuccess } = fetchLesson(course, lesson);

  onSuccess(({ data }) => {
    currentLesson.value = data;
  });
};

watchEffect(() => {
  if (!isEmpty(accountStore.user)) {
    load();
  }
});

onMounted(() => {
  if (!isEmpty(accountStore.user)) {
    load();
  }
});
</script>
